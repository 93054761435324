var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0  justify-content-center"},[_c('b-col',{staticClass:"content-header",attrs:{"cols":"9"}},[_c('h2',{staticClass:"content-header-title float-left m-0 pr-1 mr-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"},[_c('h3',{staticClass:"m-0"},[_vm._v(_vm._s((_vm.userForm.civility==0 ? 'M. ' : _vm.userForm.civility==1 ? 'Mme ' : '') + _vm.userForm.firstName+ ' ' + _vm.userForm.lastName))])]),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])],1),(_vm.isLoadingUser)?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"content-scrollable-sticky m-0 h-100 justify-content-center"},[_c('b-col',{staticClass:"content-scrollable-sticky-main",attrs:{"cols":"9"}},[_c('validation-observer',{ref:"formCreateUser"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('b-row',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Civilité","label-for":"civility"}},[_c('validation-provider',{attrs:{"name":"Civility"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"civility","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.value; },"options":_vm.optionCivility},model:{value:(_vm.userForm.civility),callback:function ($$v) {_vm.$set(_vm.userForm, "civility", $$v)},expression:"userForm.civility"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Nom *","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null},model:{value:(_vm.userForm.lastName),callback:function ($$v) {_vm.$set(_vm.userForm, "lastName", $$v)},expression:"userForm.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom *","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null},model:{value:(_vm.userForm.firstName),callback:function ($$v) {_vm.$set(_vm.userForm, "firstName", $$v)},expression:"userForm.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.userForm.email),callback:function ($$v) {_vm.$set(_vm.userForm, "email", $$v)},expression:"userForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),(_vm.userForm.id=='' || _vm.userForm.id==0)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Mot de passe *","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":{ required: true, min: 8, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.userForm.password),callback:function ($$v) {_vm.$set(_vm.userForm, "password", $$v)},expression:"userForm.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("Doit contenir au moins 8 caractères dont : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial")])]}}],null,false,423022199)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Confirmer votre mot de passe *","label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":"confirmPassword","rules":'confirmed:password',"data-vv-as":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPassword","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"confirmPassword","placeholder":"············"},model:{value:(_vm.userForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.userForm, "confirmPassword", $$v)},expression:"userForm.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("Confirmation invalide")])]}}],null,false,1191867141)})],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"phoneNumber"}},[_c('validation-provider',{attrs:{"name":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('## ## ## ## ##'),expression:"'## ## ## ## ##'"}],attrs:{"id":"phoneNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.userForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.userForm, "phoneNumber", $$v)},expression:"userForm.phoneNumber"}})]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Environnements affectués","label-for":"workspaceIds"}},[_c('validation-provider',{attrs:{"name":"workspaceIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.workspacesList)?_c('v-select',{attrs:{"id":"workspaceIds","multiple":true,"loading":_vm.isLoadingWorkspacesList,"closeOnSelect":false,"deselectFromDropdown":true,"state":"errors.length > 0 ? false : null","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.workspacesList.map(function (elem) { return { label: elem.socialReason, value: elem.id }  }),"reduce":function (elem) { return elem.value; }},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [[_vm._v("Aucun résultat trouvé")]]},proxy:true}],null,true),model:{value:(_vm.userForm.workspaceIds),callback:function ($$v) {_vm.$set(_vm.userForm, "workspaceIds", $$v)},expression:"userForm.workspaceIds"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.userForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.userForm.id ? 'Modifier' : 'Ajouter')+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Chargement...")])]),_c('br'),_vm._v(" Chargement du formulaire... ")])}]

export { render, staticRenderFns }